import eventBus from '@/utils/eventBus'

export default {
    methods: {
        handleOrderingChange() {
            this.$store.commit('filter/SET_FILTERS_ORDERING', {
                name: this.page_name,
                value: this.ordering ? [this.ordering,] : []
            })
            this.setFilters()
        },
        handleOrganizationsFilterChange(event) {
            this.filters['organizations'] = ['only_my', 'descendants'].includes(event.target.value)
            this.setFilters()
        },
        async getIDs(listItems) {
            const ids = listItems.map(item => {
                return item.id
            })
            return ids
        },
        async setActions(listItems) {
            let ids = await this.getIDs(listItems)
            if(listItems.length > 0) {
                try {
                    this.loading = true
                    const { data } = await this.$http.post('/risk_assessment/action_info/', ids)
                    if(data) {
                        listItems.forEach(item => {
                            Object.assign(item, data[item.id])
                        })
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
                return listItems
            } else {
                return []
            }
        },
        setSummaryFilters(type) {
            if(this.summaryActive?.[type])
                this.$delete(this.summaryActive, type)
            else
                this.$set(this.summaryActive, type, true)

            this.setFilters()
        },
        getSummary() {
            const params = {
                page_name: this.page_name,
                organizations: this.organizationsFilter
            }
            this.$http.get('/risk_assessment/summary/', {
                params
            })
                .then(({ data }) => {
                    this.summary = data
                })
                .catch(error => {
                    console.error(error)
                })
        },
        async getInquiries() {
            this.getList()
        },
        newInquiriesFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(this.filters['processed_inquiries'])
                this.filters['processed_inquiries'] = false
            this.setFilters()
        },
        processedInquiriesFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(this.filters['new_inquiries'])
                this.filters['new_inquiries'] = false
            this.setFilters()
        },
        async filtersInit() {
            try {
                this.loading = true

                let params = {
                    model: this.model,
                    page_name: this.page_name
                }

                const { data } = await this.$http.get('/app_info/active_filters/', {
                    params
                })
                if(data) {
                    if('total_value_filter' in data.activeFilters && data.activeFilters['total_value_filter'].active) {
                        if(data.activeFilters['total_value_filter']?.values?.value?.length) {
                            data.activeFilters['total_value_filter'].values.value.forEach(item => {
                                this.$set(this.summaryActive, item, true)
                            })
                        }
                    }
                    if('issue_date_filter' in data.activeFilters && data.activeFilters['issue_date_filter'].active) {
                        if(data.activeFilters['issue_date_filter'].values.start === this.$moment().startOf('month').format('YYYY-MM-DD') && data.activeFilters['issue_date_filter'].values.end === this.$moment().endOf('month').format('YYYY-MM-DD')) {
                            this.filters['current_month'] = true
                        } else if(data.activeFilters['issue_date_filter'].values.start === this.$moment().startOf('year').format('YYYY-MM-DD') && data.activeFilters['issue_date_filter'].values.end === this.$moment().endOf('year').format('YYYY-MM-DD')) {
                            this.filters['current_year'] = true
                        } else {
                            this.filters['period'] = true
                            this.periodRange = [
                                this.$moment(data.activeFilters['issue_date_filter'].values.start),
                                this.$moment(data.activeFilters['issue_date_filter'].values.end)
                            ]
                            this.periodStart = this.$moment(data.activeFilters['issue_date_filter'].values.start)
                            this.periodEnd = this.$moment(data.activeFilters['issue_date_filter'].values.end)
                        }
                    }
                    if('status' in data.activeFilters && data.activeFilters['status'].active)
                        if(data.activeFilters['status'].values.value[0] === 'new') {
                            this.filters['new_inquiries'] = true
                        } else if(data.activeFilters['status'].values.value[0] === 'processed') {
                            this.filters['processed_inquiries'] = true
                        }
                    if('organizations_filter' in data.activeFilters && data.activeFilters['organizations_filter'].active) {
                        this.organizationsFilter = data.activeFilters['organizations_filter'].values.value
                    } else {
                        this.organizationsFilter = 'all'
                    }
                    if(data?.ordering.length) {
                        this.ordering = data.ordering[0]
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async updateAssessmentInList(data) {
            const index = this.list.findIndex(f => f.id === data.id)
            if(index !== -1) {
                const new_data = await this.setActions([data])
                this.$set(this.list, index, new_data[0])
                eventBus.$emit('update_assessment_details', new_data[0].id)
            }
        },
    }
}