<template>
    <a-drawer
        :title="edit ? 'Редактировать обращение' : 'Добавить обращение'"
        :visible="visible"
        class="de_drawer"
        @close="visible = false"
        destroyOnClose
        :zIndex="zIndex"
        :width="drawerWidth"
        :afterVisibleChange="afterVisibleChange"
        placement="right">
        <div class="forma">
            <a-form-model
                ref="riskAssessmentForm"
                :model="form"
                :rules="rules">
                <template v-if="isMobile">
                    <div class="mobile-drawer-body w-full" ref="riskAssessmentAddBody">
                        <div class="user">
                            <div class="label">Ответственный:</div>
                            <Profiler class="pt-2" :user="user" />
                        </div>
                        <div class="issue_date">
                            <a-form-model-item ref="issue_date" label="Дата поступления обращения:" prop="issue.issue_date">
                                <a-date-picker
                                    v-model="form.issue.issue_date"
                                    :getPopupContainer="trigger => trigger.parentElement"
                                    placeholder="Выберите дату"
                                    class="w-full"
                                    format="DD.MM.YYYY"
                                    size="large" />
                            </a-form-model-item>
                        </div>
                        <div class="organization truncate">
                            <template v-if="edit">
                                <div class="">Организация:</div>
                                <div class="organization_edit items-center pt-2">
                                    <span :key="form?.organization?.logo" class="pr-2">
                                        <a-avatar 
                                            :size="30"
                                            :src="form?.organization?.logo"
                                            icon="fi-rr-users-alt" 
                                            flaticon />
                                    </span>
                                    <a-tooltip placement="topLeft" :title="form?.organization?.name">
                                        <span class="break-all truncate">
                                            {{ form?.organization?.name }}
                                        </span>
                                    </a-tooltip>
                                </div>
                            </template>
                            <template v-else>
                                <a-form-model-item ref="organization" label="Организация" prop="organization">
                                    <a-select
                                        v-model="form.organization"
                                        size="large"
                                        :getPopupContainer="getPopupContainer"
                                        :loading="myOrganizationsLoading"
                                        placeholder="Выберите организацию">
                                        <a-select-option v-for="org in myOrganizations" :key="org.id" :value="org.id">
                                            <div class="truncate">{{ org.name }}</div>
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                        </div>
                        <div class="number">
                            <a-form-model-item ref="number" label="Номер обращения:" prop="issue.number">
                                <a-input
                                    size="large"
                                    v-model="form.issue.number"
                                    placeholder="Введите номер обращения" />
                            </a-form-model-item>
                        </div>
                        <div class="issue_type">
                            <a-form-model-item ref="issue_type" label="Вид обращения:" prop="issue.issue_type">
                                <a-select
                                    size="large"
                                    :getPopupContainer="trigger => trigger.parentElement"
                                    :loading="issueTypesLoading"
                                    v-model="form.issue.issue_type"
                                    placeholder="Выберите вид обращения" >
                                    <a-select-option v-for="item in issueTypes" :value="item.code" :key="item.id">
                                        {{ item.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </div>
                        <div class="summary">
                            <a-form-model-item ref="summary" label="Категория вопроса:" prop="issue.summary">
                                <a-input
                                    size="large"
                                    v-model="form.issue.summary"
                                    placeholder="Укажите категорию вопроса" />
                            </a-form-model-item>
                        </div>
                        <div class="sent_for">
                            <a-form-model-item ref="sent_for" label="Направлено на рассмотрение:" prop="sent_for">
                                <a-radio-group v-model="form.sent_for" :default-value="0" size="large">
                                    <a-radio :value="0">
                                        Первый руководитель/Заместители
                                    </a-radio>
                                    <a-radio :value="1" class="mt-3">
                                        Руководитель аппарата
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                        </div>
                        <div class="issue_text">
                            <a-form-model-item ref="text" label="Текст обращения (при необходимости):" prop="issue.text">
                                <a-textarea
                                    v-model="form.issue.text"
                                    allowClear
                                    placeholder="Текст обращения"
                                    :auto-size="{ minRows: 10, maxRows: 10 }" />
                            </a-form-model-item>
                        </div>
                        <div class="risk_assessment">
                            <div class="risk_assessment_list">
                                <div class="mb-3">Выделите критерии риска обращения:</div>
                                <a-spin :spinning="riskAssessmentCriteriaLoading" class="mb-3">
                                    <div
                                        v-for="item in form.risk_assessment_criteria"
                                        :key="item.id"
                                        class="ra_list_item cursor_pointer"
                                        :class="item?.value && 'bg-blue-200'"
                                        @click="clickHandler(item)">
                                        {{ item.string_view }}
                                    </div>
                                </a-spin>
                                <div class="ra_total_item w-full" :class="backgroundColorClass()">Итоговое значение: {{ total }}</div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="drawer_body w-full" ref="riskAssessmentAddBody">
                        <div class="risk_assessment">
                            <div class="risk_assessment_list">
                                <div class="label">Выделите критерии риска обращения:</div>
                                <a-spin :spinning="riskAssessmentCriteriaLoading">
                                    <div v-for="item in form.risk_assessment_criteria" :key="item.id">
                                        <a-tooltip placement="right" :overlayStyle="{zIndex: 1100}">
                                            <template slot="title">
                                                <span>Нажмите</span>
                                            </template>
                                            <div
                                                class="ra_list_item cursor_pointer"
                                                :class="item?.value && 'bg-blue-200'"
                                                @click="clickHandler(item)">
                                                {{ item.string_view }}
                                            </div>
                                        </a-tooltip>
                                    </div>
                                </a-spin>
                                <div class="ra_total_item label w-full" :class="backgroundColorClass()">Итоговое значение: {{ total }}</div>
                            </div>
                        </div>
                        <div class="three_items">
                            <div>
                                <div class="responsible">Ответственный:</div>
                                <Profiler class="" :user="user" />
                            </div>
                            <div class="organization truncate">
                                <template v-if="edit">
                                    <div class="responsible">Организация:</div>
                                    <div class="organization_edit items-center pt-2">
                                        <span :key="form?.organization?.logo" class="pr-2">
                                            <a-avatar 
                                                :size="30"
                                                :src="form?.organization?.logo"
                                                icon="fi-rr-users-alt" 
                                                flaticon />
                                        </span>
                                        <a-tooltip placement="topLeft" :title="form?.organization?.name">
                                            <span class="break-all truncate">
                                                {{ form?.organization?.name }}
                                            </span>
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template v-else>
                                    <a-form-model-item ref="organization" label="Организация" prop="organization">
                                        <a-select
                                            v-model="form.organization"
                                            size="large"
                                            :getPopupContainer="getPopupContainer"
                                            :loading="myOrganizationsLoading"
                                            placeholder="Выберите организацию">
                                            <a-select-option v-for="org in myOrganizations" :key="org.id" :value="org.id">
                                                <div class="truncate">{{ org.name }}</div>
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </template>
                            </div>
                            <div class="issue_date">
                                <a-form-model-item ref="issue_date" label="Дата поступления обращения:" prop="issue.issue_date">
                                    <a-date-picker
                                        v-model="form.issue.issue_date"
                                        :getPopupContainer="trigger => trigger.parentElement"
                                        placeholder="Выберите дату"
                                        class="w-full"
                                        format="DD.MM.YYYY"
                                        size="large" />
                                </a-form-model-item>
                            </div>
                        </div>
                        <div class="number">
                            <a-form-model-item ref="number" label="Номер обращения:" prop="issue.number">
                                <a-input
                                    size="large"
                                    v-model="form.issue.number"
                                    placeholder="Введите номер обращения" />
                            </a-form-model-item>
                        </div>
                        <div class="issue_type">
                            <a-form-model-item ref="issue_type" label="Вид обращения:" prop="issue.issue_type">
                                <a-select
                                    size="large"
                                    :getPopupContainer="trigger => trigger.parentElement"
                                    :loading="issueTypesLoading"
                                    v-model="form.issue.issue_type"
                                    placeholder="Выберите вид обращения" >
                                    <a-select-option v-for="item in issueTypes" :value="item.code" :key="item.id">
                                        {{ item.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </div>
                        <div class="summary">
                            <a-form-model-item ref="summary" label="Категория вопроса:" prop="issue.summary">
                                <a-input
                                    size="large"
                                    v-model="form.issue.summary"
                                    placeholder="Укажите категорию вопроса" />
                                <!-- <a-select
                                    size="large"
                                    :loading="summariesLoading"
                                    class="w-full"
                                    v-model="form.issue.summary">
                                    <a-select-option v-for="item in summaries" :value="item.value" :key="item.value">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select> -->
                            </a-form-model-item>
                        </div>
                        <div class="sent_for">
                            <a-form-model-item ref="sent_for" label="Направлено на рассмотрение:" prop="sent_for">
                                <a-radio-group v-model="form.sent_for" :default-value="0" size="large">
                                    <a-radio-button :value="0">
                                        Первый руководитель/Заместители
                                    </a-radio-button>
                                    <a-radio-button :value="1">
                                        Руководитель аппарата
                                    </a-radio-button>
                                </a-radio-group>
                            </a-form-model-item>
                        </div>
                        <div class="issue_text">
                            <a-form-model-item ref="text" label="Текст обращения (при необходимости):" prop="issue.text">
                                <a-textarea
                                    v-model="form.issue.text"
                                    allowClear
                                    placeholder="Текст обращения"
                                    :auto-size="{ minRows: 10, maxRows: 10 }" />
                            </a-form-model-item>
                        </div>
                    </div>
                </template>
            </a-form-model>
        </div>
        <div class="drawer_footer">
            <a-button 
                type="primary"
                :loading="loading"
                @click="formSubmit()">
                {{ submitButtonText }}
            </a-button>
            <a-button 
                type="ui"
                class="ml-2"
                :loading="loading"
                @click="visible = false">
                Отменить
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    name: 'NewInquir',
    components: {
    },
    props: {
        zIndex: {
            type: Number,
            default: 1050
        },
        pageName: {
            type: String,
            default: null
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        drawerWidth() {
            if(this.isMobile) {
                return '100%'
            } else if(this.windowWidth > 1400) {
                return 1400
            } else {
                return '95%'
            }
        },
        ...mapState({
            user: state => state.user.user,
        }),
        submitButtonText() {
            return this.edit ? 'Сохранить' : 'Создать'
        }
    },
    data() {
        return {
            edit: false,
            loading: false,
            myOrganizations: [],
            myOrganizationsLoading: false,
            summariesLoading: false,
            riskAssessmentCriteriaLoading: false,
            visible: false,
            issueTypesLoading: false,
            issueTypes: [],
            total: 0,
            form: {
                organization: null,
                assessment_type: 'initial',
                issue: {
                    issue_type: null,
                    number: "",
                    summary: "",
                    text: "",
                    issue_date: null
                },
                risk_assessment_criteria: [],
                sent_for: 0, // Направлено для рассмотрения. Значения 0 или 1
            },
            rules: {
                organization: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                "issue.issue_date": [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                "issue.number": [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                "issue.issue_type": [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                "issue.summary": [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
            },
        }
    },
    created() {
        eventBus.$on('new_inquir', () => {
            this.visible = true
        })
        eventBus.$on('edit_inquir', async (id) => {
            this.edit = true
            await this.getAssessment(id)
            this.visible = true
        })
    },
    methods: {
        backgroundColorClass() {
            if (this.total >= 1 && this.total <= 2) {
                return 'bg-yellow'
            } else if (this.total >= 3 && this.total <= 5) {
                return 'bg-orange'
            } else if (this.total >= 5) {
                return 'bg-red'
            } else {
                return ''
            }
        },
        getTotal() {
            this.total = 0
            this.form.risk_assessment_criteria.forEach(each => {
                if(each.value === 1)
                    this.total += 1
            })
        },
        async getAssessment(id) {
            if(!this.assessmentLoading) {
                try {
                    this.myOrganizationsLoading = true
                    this.summariesLoading = true
                    this.riskAssessmentCriteriaLoading = true
                    const { data } = await this.$http.get(`risk_assessment/${id}/`)
                    if(data) {
                        this.form = {...data}
                        if(this.form.issue.issue_type?.code)
                            this.form.issue.issue_type = this.form.issue.issue_type?.code
                        if(data.risk_assessment_criteria.length) {
                            this.form.risk_assessment_criteria = data.risk_assessment_criteria.map(item => {
                                return {
                                    "criteria": item.criteria.id,
                                    "code.criteria": item.code,
                                    "string_view": item.criteria.name,
                                    "value": item.value
                                }
                            })
                            this.getTotal()
                        }
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.myOrganizationsLoading = false
                    this.summariesLoading = false
                    this.riskAssessmentCriteriaLoading = false
                }
            }
        },
        clickHandler(item) {
            const index = this.form.risk_assessment_criteria.findIndex(each => each.criteria === item.criteria)
            if(index !== -1) {
                if(this.form.risk_assessment_criteria[index]['value'] === 1)
                    this.form.risk_assessment_criteria[index]['value'] = 0
                else
                    this.form.risk_assessment_criteria[index]['value'] = 1
                
                this.getTotal()
            }
        },
        async getRiskAssessmentCriteriaList() {
            if(!this.riskAssessmentCriteriaLoading) {
                try {
                    this.riskAssessmentCriteriaLoading = true
                    const { data } = await this.$http.get('app_info/select_list/', {
                        params: {
                            model: 'risk_assessment.AssessmentCriteriaModel'
                        }
                    })
                    if(data.selectList.length) {
                        this.form.risk_assessment_criteria = data.selectList.map(item => {
                            return {
                                "criteria": item.id,
                                "code": item.code,
                                "string_view": item.string_view,
                                "value": 0
                            }
                        })
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.riskAssessmentCriteriaLoading = false
                }
            }
        },
        async getIssueTypes() {
            if(!this.issueTypesLoading) {
                try {
                    this.issueTypesLoading = true
                    const { data } = await this.$http.get('app_info/select_list/?model=risk_assessment.IssueTypeModel')
                    if(data.selectList.length) {
                        this.issueTypes = data.selectList
                        if(!this.edit && data.selectList.length === 1)
                            this.form.issue_type = data.selectList[0].id
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.issueTypesLoading = false
                }
            }
        },
        async getMyOrganizations() {
            if(!this.myOrganizationsLoading) {
                const params = {
                    permission_type: 'create_risk_assessment'
                }
                try {
                    this.myOrganizationsLoading = true
                    const { data } = await this.$http.get(`/contractor_permissions/organizations/`, {
                        params: params
                    })
                    if(data.length) {
                        this.myOrganizations = data
                        if(!this.edit && data.length === 1)
                            this.form.organization = data[0].id
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.myOrganizationsLoading = false
                }
            }
        },
        async formSubmit() {
            this.$refs.riskAssessmentForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                
                        const formData = JSON.parse(JSON.stringify(this.form))
                        if(formData.issue.issue_date) {
                            formData.issue.issue_date = this.$moment(formData.issue.issue_date).format('YYYY-MM-DD')
                        }

                        if(this.edit) {
                            const { data } = await this.$http.put(`/risk_assessment/${formData.id}/`, formData)
                            if(data) {
                                this.visible = false
                                eventBus.$emit('update_assessment_in_list', data)
                                this.$message.info('Обращение обновлено')
    
                            }
                        } else {
                            const { data } = await this.$http.post('/risk_assessment/', formData)
                            if(data) {
                                this.visible = false
                                eventBus.$emit('assessment_list_reload')
                                this.$message.info('Обращение создано')  
                            }

                        }
                    } catch(e) {
                        console.log(e)
                        this.$message.error(e[0] ? e[0] : 'Ошибка')
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$message.error('Некорректные данные')
                    return false
                }
            })
        },
        getPopupContainer() {
            return this.$refs['riskAssessmentAddBody']
        },
        async afterVisibleChange(vis) {
            if(vis) {
                this.getMyOrganizations()
                this.getIssueTypes()
                if(!this.edit) {
                    this.getRiskAssessmentCriteriaList()
                }
            } else {
                this.myOrganizations = []
                this.issueTypes = []
                this.form = {
                    organization: null,
                    assessment_type: 'initial',
                    issue: {
                        issue_type: null,
                        number: "",
                        summary: "",
                        text: "",
                        issue_date: null
                    },
                    risk_assessment_criteria: [],
                    sent_for: 0
                },
                this.edit = false
                this.total = 0
            }
        },
    },
    beforeDestroy() {
        eventBus.$off('new_inquir')
        eventBus.$off('edit_inquir')
    }
}
</script>

<style lang="scss" scoped>
.de_drawer{
    &::v-deep{
        .forma {
            height: inherit;
            overflow-y: auto;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
        }
        .mobile-drawer-body{
            overflow-y: auto;
            overflow-x: hidden;
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            row-gap: 20px;
            .label {
            }
            .user{
            }
            .organization {
                width: 100%;
            }
            .issue_text {
                display: flex;
                flex-direction: column;
                height: 200px;
                width: 100%;
                min-width: 0;
                .ck-editor {
                    display: flex; 
                    flex-direction: column;
                    flex-grow: 1;
                    min-height: 0;
                }
                .ck-editor__main {
                    min-height: 0;
                    flex-grow: 1;
                }
                .ck-editor__editable_inline {
                    height: 100%;
                }
                .text-label{
                    line-height: 26px;
                    color: rgba(0, 0, 0, 0.85);
                    padding-bottom: 8px;
                }
            }
            .risk_assessment {
                grid-row: span 5;
                .risk_assessment_list{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr;
                    align-content: space-between;
                    height: 100%;
                    .ra_list_item {
                        border-radius: var(--borderRadius);
                        border: 1px solid var(--border1);
                        margin-top: 1px;
                        padding: 5px;
                        cursor: pointer;
                    }
                    .ra_total_item {
                        border-radius: var(--borderRadius);
                        border: 1px solid var(--border1);
                        margin-top: 1px;
                        padding: 10px;
                        align-self: end;
                    }
                        .bg-yellow {
                        background-color: yellow;
                    }
                        .bg-orange {
                        background-color: orange;
                    }
                        .bg-red {
                        background-color: red;
                    }
                }
            }
        }
        .drawer_body{
            overflow-y: auto;
            overflow-x: hidden;
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 250px;
            row-gap: 10px;
            column-gap: 20px;
            .label {
                font-weight: 600;
                line-height: 26px;
            }
            .risk_assessment {
                grid-row: span 5;
                .risk_assessment_list{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr;
                    align-content: space-between;
                    height: 100%;
                    .ra_list_item {
                        border-radius: var(--borderRadius);
                        border: 1px solid var(--border1);
                        margin-top: 1px;
                        padding: 5px;
                        cursor: pointer;
                    }
                    .ra_total_item {
                        border-radius: var(--borderRadius);
                        border: 1px solid var(--border1);
                        margin-top: 1px;
                        padding: 10px;
                        align-self: end;
                    }
                        .bg-yellow {
                        background-color: yellow;
                    }
                        .bg-orange {
                        background-color: orange;
                    }
                        .bg-red {
                        background-color: red;
                    }
                }
            }
            .three_items {
                grid-column: span 2;
                display: grid;
                grid-template-columns: 1fr 1.7fr 1fr;
                gap: 20px;
                .responsible{
                    line-height: 26px;
                }
                .organization {
                    width: 100%;
                }
                .issue_date {
                    width: 100%;
                }
                .organization_edit {
                    display: grid;
                    grid-template-columns: max-content 1fr;
                }
            }
            .responsible {
            }
            .number {
                width: 100%;
            }
            .issue_type {
                width: 100%;
            }
            .summary {
                grid-column: span 2;
            }
            .sent_for {
                grid-column: span 2;
            }
            .issue_text {
                grid-column: span 2;
            }
            .submt_button {
                grid-column: span 2;
            }
        }
        .drawer_footer{
                display: flex;
                align-items: center;
                height: 40px;
                border-top: 1px solid #e8e8e8;
                padding-left: 20px;
                padding-right: 20px;
            }
    }
}
</style>